import React, { useEffect, useState } from 'react';
import InputCurrencyFormat from '../widget/Input/numberCurrency';
import { NumericFormat } from 'react-number-format';

function ValEstipulado({ finalidade }) {
    const [valorEstipulado, setValorEstipulado] = useState("0.00");
    const [ipraCalculado, setIpraCalculado] = useState(0);
    const [currency, setCurrency] = useState("0.00");
    console.log(finalidade)
    const calcularIpra = (valorEstipulado, finalidade) => {
        var val = valorEstipulado.replaceAll('.', '')
        var val = val.replaceAll(',', '')
        const taxa = finalidade === 'habitacao' ? 0.004 : (finalidade === 'comercial' ? 0.007 : 0);
        const ipraCalculado = parseFloat(val) * taxa;
        setIpraCalculado(ipraCalculado);
    };

    const handleInputChange = (value) => {
        if (finalidade == null || finalidade == "Escolha a finalidade") {
            alert("Escolha a finalidade.")
        } else {            
            setValorEstipulado(value);
            calcularIpra(value, finalidade);
        }
    };

    function currencyUpdate(value) {
        if (finalidade == null || finalidade == "Escolha a finalidade") {
            alert("Escolha a finalidade.")
        } else setCurrency(value)
    }

    useEffect(() => {
        calcularIpra(valorEstipulado, finalidade);
    }, [finalidade]);

    return (
        <div className='form-container' style={{ backgroundColor: "lightgray", textAlign: "center" }}>
            <br />
            <div>
                <label>
                    Introduza o valor Estipulado para o imóvel:
                </label>
            </div>
            <div>
                {/* <input
                    type="text"
                    placeholder='Valor estipulado'
                    style={{
                        textAlign: "center",
                    }}
                    value={valorEstipulado}
                    pattern="^\d+(?:\.\d{1,2})?$"
                    onblur="this.parentNode.parentNode.style.backgroundColor=/^\d+(?:\.\d{1,2})?$/.test(this.value)?'inherit':'red'"
                    onChange={handleInputChange}
                    min={0}
                    step="0.01"
                /> */}
                {/* <InputCurrencyFormat 
                    value={valorEstipulado}
                    init={initValorEstipulado}
                    setValue={setValorEstipulado}
                    setInitValue={setInitValorEstipulado}
                    currency={currency} 
                    finalidade={finalidade} 
                    onValueUpdate={currencyUpdate} decimal_separator="." thousands_separator="," fractionSize="2" setIpraValue={calcularIpra} /> */}
                
                <NumericFormat
                    required
                    placeholder={" "}
                    onValueChange={(values) => {
                        const { value } = values;
                        handleInputChange(value);
                    }}
                    style={{
                        textAlign: "center",
                    }}
                    value={valorEstipulado}
                    thousandSeparator={'.'}
                    decimalSeparator = {','}
                    // prefix={"MZN "}
                    decimalScale={1}
                />
            </div>
            <div>
                <br />
                <p><b>Valor IPRA: {ipraCalculado.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} MZN</b></p>
            </div>
            <br />
        </div>
    )
}

export default ValEstipulado;