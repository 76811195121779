import axio from "axios";

export const baseurl = axio.create({
  baseURL: "https://api.stm.atendimento.co.mz/api",
  // baseURL: "http://127.0.0.1:8000/api",
  // baseURL: "https://e-stm.co.mz/apistm/api",

});

baseurl.interceptors.request.use(function (config) {
  const token = localStorage.getItem("token");
  if (token != null) {
    config.headers.Authorization = `Token ${token}`;
  }
  return config;
});
