import React from 'react'

function Footer() {
    const year = new Date().getFullYear(0)
    return (
        <footer className="bg-body-tertiary text-center text-lg-start">
            <div className="text-center p-3" style={{ backgroundColor: "background-color: rgba(0, 0, 0, 0.05)" }} >
                {year} - Desenvolvido por Speranza Lda.
                {/* <a className="text-body" href="https://mdbootstrap.com/">MDBootstrap.com</a> */}
            </div>
        </footer>
    )
}

export default Footer