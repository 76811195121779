import React from "react";
import SimuladorValPatrimonialIpra from "../components/val_patrimonial_ipra/valPatrimonialIpra";
import Footer from "../components/layout/footer/footer";
import Navbar from "../components/layout/header/navbar";
import "./style.css"
function IpraSimulador() {
    return (
        <>
            <Navbar />
            <br />
            <br />
            <SimuladorValPatrimonialIpra />
            {/* <Footer /> */}
        </>
    )
}

export default IpraSimulador