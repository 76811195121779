import React from 'react';
import { Container } from 'react-bootstrap';
import LogoESTM from '../../assets/images/logo-estm.png';
import logoANNAM from '../../assets/images/logo-annam.jpg';
import './nav.css';

function Navbar() {
  return (
    <div className="navbar fixed-top" style={{ backgroundColor: "white", borderBottom: '1px solid black' }}>
      <Container>
        <div className="d-flex justify-content-between align-items-center ">
          <div className='div-left-fluid' >
            <img
              src={LogoESTM}
              className="img-fluid"
              alt="Logo Esquerda"
            />
          </div>
          <div >
            <img
              src={logoANNAM}
              className="img-fluid-annam"
              alt="Logo Direita"
            />
          </div>
        </div>
      </Container>
    </div>
  );
}

export default Navbar;
