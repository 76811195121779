import React, { useEffect, useState } from 'react';
import { baseurl } from '../lib/requests';
import backgroundImage from '../assets/images/mainbackground.jpg';
import './style.css';
import ValEstipulado from './valEstipulado';
import Footer from '../layout/footer/footer';



const SimuladorValPatrimonialIpra = () => {

  const [dados, setDados] = useState(Array(6).fill(""));
  const [valorPatrimonial, setValorPatrimonial] = useState("");
  const [contyData, setCountyData] = useState([]);
  const [selectedProvince, setSelectedProvince] = useState(0);
  const [selectedCounty, setSelectedCounty] = useState(null);
  const [ipraDetails, setIpraDetails] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [finalidade, setFinalidade] = useState(null);
  const [valueAL, setValueAL] = useState(0);
  const [ipraValue, setIpraValue] = useState(0);
  const [fatoresAntiguidadeSelecionados, setFatoresAntiguidadeSelecionados] = useState({});
  const [shouldRecalculate, setShouldRecalculate] = useState(false);
  const [showAlternativeForm, setShowAlternativeForm] = useState(true);


  useEffect(() => {
    if (shouldRecalculate) {
      recalculateValues();
      setShouldRecalculate(true);
    }
    calcularValorPatrimonial();
    calculoAL();
  }, [dados, ipraDetails, shouldRecalculate]);



  useEffect(() => {
    if (finalidade) {
      const fatoresAntiguidadeFinalidade = finalidade === 'habitacao' ? fatoresAntiguidade.habitacao : fatoresAntiguidade.comercial;
      setFatoresAntiguidadeSelecionados(fatoresAntiguidadeFinalidade);
    }
  }, [finalidade]);

  const handleInputChange = (index, value) => {
    const novosDados = [...dados];
    novosDados[index] = value;

    if (index === 0 && parseFloat(value) <= 0) {
      alert("A área do terreno deve ser maior que zero.");
      setDados(["", novosDados[1], novosDados[2], novosDados[3], novosDados[4], novosDados[5]]);
      return;
    }

    if (index === 1 && parseFloat(value) <= 0) {
      alert("A área edificada deve ser maior que zero.");
      setDados([novosDados[0], "", novosDados[2], novosDados[3], novosDados[4], novosDados[5]]);
      return;
    }

    if (index === 1 && parseFloat(value) > parseFloat(dados[0])) {
      alert('A área edificada não pode ser maior que a área do terreno.');
      setDados([novosDados[0], "", novosDados[2], novosDados[3], novosDados[4], novosDados[5]]);
      return;
    }

    setDados(novosDados);

    if (index === 4) {
      const anoAtual = new Date().getFullYear();
      const idade = anoAtual - parseInt(value);
      if (idade >= 0) {
        const fatoresAntiguidadeFinalidade = finalidade === 'habitacao' ? fatoresAntiguidade.habitacao : fatoresAntiguidade.comercial;
        const fa = fatoresAntiguidadeFinalidade[idade] || 0;
        novosDados[3] = fa;
        setDados(novosDados);
        calcularValorPatrimonial();
        setShouldRecalculate(true);
      }
    } else {
      calcularValorPatrimonial();
      setShouldRecalculate(true);
    }
  };


  const calcularValorPatrimonial = () => {
    const At = parseFloat(dados[0] || 0);
    const Ae = parseFloat(dados[1] || 0);
    const PMC = ipraDetails.data && ipraDetails.data.length > 0 ? parseFloat(ipraDetails.data[0]?.preco_m_construcao || 0) : 0;
    const Fa = parseFloat(dados[3] || 0);
    const FI = ipraDetails.data && ipraDetails.data.length > 0 ? parseFloat(ipraDetails.data[0]?.['fator_localizacao '] || 0) : 0;

    if (Fa === 0) {
      setIpraValue("ISENTO - ");
      return;
    }

    const valorPatrimonialCalculado = ((Ae * PMC * Fa) + (0.05 * valueAL * PMC)) * FI;
    const formatedPatrimonialValue = valorPatrimonialCalculado.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
    setValorPatrimonial(formatedPatrimonialValue);

    const taxa = finalidade === 'habitacao' ? 0.004 : (finalidade === 'comercial' ? 0.007 : 0);
    const ipraCalculado = valorPatrimonialCalculado * taxa;
    setIpraValue(ipraCalculado.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }));
    setShouldRecalculate(false);
  };

  const recalculateValues = () => {
    calcularValorPatrimonial();
    calculoAL();
  };


  const searchCountys = (e) => {
    setIsLoading(true);
    const data = {
      province: e
    }
    baseurl.post('v1/ai/assets/municipios_by_province/', data).then((response) => {
      setCountyData(response.data);
      setIsLoading(false);
    }).catch((error) => {
      console.log("ERROR: ", error);
      setIsLoading(false);
    }, []);
  };

  const searchCountyDetails = (countyId) => {
    setIsLoading(true);
    baseurl.post("v1/ipra_details_conty/", { municipio: countyId })
      .then((response) => {
        setIpraDetails(response.data)
        setIsLoading(false);
      })
      .catch((error) => {
        console.log("ERROR: ", error);
        setIsLoading(false);
      });
  };

  const handleCountySelect = (e) => {
    const countyId = e.target.value;
    setSelectedCounty(countyId);
    searchCountyDetails(countyId);
    recalculateValues();
  };

  const fatoresAntiguidade = {
    habitacao: {
      "Menos de 5 anos": 0,
      "5 a 10 anos": 1,
      "11 a 15 anos": 0.95,
      "16 a 20 anos": 0.90,
      "21 a 30 anos": 0.85,
      "31 a 40 anos": 0.75,
      "41 a 50 anos": 0.65,
      "Mais de 50 anos": 0.55
    },

    comercial: {
      "Menos de 5 anos": 0,
      "5 a 10 anos": 0.95,
      "11 a 15 anos": 0.90,
      "16 a 20 anos": 0.85,
      "21 a 30 anos": 0.80,
      "31 a 40 anos": 0.75,
      "41 a 50 anos": 0.70,
      "Mais de 50 anos": 0.65
    }
  };

  const handleFinalidadeSelect = (e) => {
    setFinalidade(e.target.value);
    calcularValorPatrimonial();
    recalculateValues();
  };

  const calculoAL = () => {
    const AT = parseFloat(dados[0] || 0);
    const AE = parseFloat(dados[1] || 0);
    const Al = AT - AE;
    setValueAL(Al);
  }
  // backgroundImage: `url(${backgroundImage})`, backgroundSize: "cover", 
  return (
    <div style={{ height: "95vh", backgroundImage: `url(${backgroundImage})`, backgroundSize: "cover" }}>
      <div className="simulador" style={{ margin: '0 auto', maxWidth: '800px', }}>
        <br />
        <br />

        <div className="simulador-header">
          <h3 className='patrimonial-value-fontsize'>SIMULADOR DE VALOR PATRIMONIAL (IPRA)</h3>
          <br />
          {/* <br /> */}

        </div>
        <div className="form-check form-switch" style={{ display: "flex", justifyContent: 'flex-end', alignItems: 'center', backgroundColor: 'lightgray', borderRadius: '5px', paddingRight: '20px' }}>

          <div>
            <label className="form-check-label" htmlFor="flexSwitchCheckDefault">{showAlternativeForm ? 'Valor Calculado' : 'Valor Estipulado'}</label>
          </div>

          <div style={{ display: "flex", alignItems: "center", width: "10px", height: "35px", marginLeft: "auto" }}>
            <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" onClick={() => setShowAlternativeForm(!showAlternativeForm)} style={{ flex: "1", height: "30px", width: "100%" }} />
          </div>

        </div>
        <br />
        <br />
        <div className="select-container">

          <label>
            Finalidade
            <select
              className="form-select"
              onChange={handleFinalidadeSelect}
              defaultValue=""
              class="form-select"
            >
              <option > Escolha a finalidade</option>
              <option value="habitacao">Habitação</option>
              <option value="comercial">Comercial/Mista</option>
            </select>
          </label>
          <br />
          <label>
            Província
            <select className="form-select" onChange={(e) => searchCountys(e.target.value)} onClick={(e) => { setSelectedProvince(e.target.value) }} aria-label="Default select example" disabled={isLoading}>
              <option >Escolha a sua Província </option>
              <option value="1">Manica</option>
              <option value="2">Sofala</option>
              <option value="3">Tete</option>
              <option value="4">Zambézia</option>
              <option value="5">Nampula</option>
              <option value="6">Cabo-Delgado</option>
              <option value="7">Niassa</option>
              <option value="8">Maputo Cidade</option>
              <option value="9">Mauto Província.</option>
              <option value="10">Inhambane</option>
              <option value="11">Gaza</option>
            </select>
          </label>
          <br />

          {/* {(isLoading && <>
          <div style={{ textAlign: 'center' }}>
            <div>

              <div class="spinner-border text-center" role="status">
                <span class="sr-only"></span>
              </div>
            </div>
            Processando...
          </div>
        </>) || ( */}
          <>
            <label>
              Município

              <select onChange={handleCountySelect} className="form-select" aria-label="Default select example" disabled={!selectedProvince}>
                <option>Escolha um município</option>
                {Array.isArray(contyData.data) && contyData.data.map((item) => (
                  <option key={item.id_municipio} value={item.id_municipio}>{item.nome_municipio}</option>
                ))}
              </select>
            </label>
          </>
          {/* )} */}
        </div>
        <br />

        {showAlternativeForm ? <>
          <table className="table table-striped table-bordered table-hover minha-tabela">
            <thead style={{}}>
              <tr style={{ backgroundColor: '#f2f2f2' }}>
                <th className='verical-information' >Variáveis</th>
                <th className='verical-information' style={{ textAlign: 'center' }}>Designação</th>
                <th className='verical-information' style={{ textAlign: 'center' }}>Dados</th>
                <th className='indicador-medida vertical-information'>Indicador de Medida</th>
              </tr>
            </thead>
            {Array.isArray(ipraDetails.data) && ipraDetails.data.map((item, index) => (
              <tbody key={index}>
                <tr>
                  <td className='align-vertically-center'>At</td>
                  <td className='align-vertically-center'>Área do Terreno</td>
                  <td className='align-vertically-center'>
                    <input
                      placeholder='Área do terreno'
                      style={{
                        textAlign: "center",
                        width: "100%"
                      }}
                      type="text"
                      value={dados[0]}
                      onChange={(e) => handleInputChange(0, e.target.value)}
                    />
                  </td>
                  <td className='indicador-medida'>m²</td>
                </tr>
                <tr>
                  <td className='align-vertically-center'>Ae</td>
                  <td className='align-vertically-center'>Área Edificada</td>
                  <td className='align-vertically-center'>
                    <input
                      placeholder='Área edificada'

                      style={{
                        textAlign: "center",
                        width: "100%"
                      }}
                      type="text"
                      value={dados[1]}
                      onChange={(e) => handleInputChange(1, e.target.value)}
                    />
                  </td>
                  <td className='indicador-medida'>m²</td>
                </tr>
                <tr>
                  <td className='align-vertically-center'>P</td>
                  <td className='align-vertically-center'>Preço Médio de Construção por m²</td>
                  <td className='indicador-medida'>
                    <span>{item.preco_m_construcao}</span>
                  </td>
                  <td className='indicador-medida'>Metical</td>
                </tr>
                <tr>
                  <td className='align-vertically-center'>Fa</td>
                  <td className='align-vertically-center'>Fator de Antiguidade do Prédio Urbano</td>
                  <td className='align-vertically-center'>

                    <select style={{
                      textAlign: "right",
                      height: "34px"
                    }}

                      value={dados[3]}
                      onChange={(e) => handleInputChange(3, e.target.value)}
                      className="form-select"
                      aria-label="Default select example"
                    >Idade do prédio
                      <option>Idade do prédio</option>

                      {Object.entries(fatoresAntiguidadeSelecionados).map(([opcao, valor]) => (
                        <option key={opcao} value={valor}>{opcao}</option>
                      ))}
                    </select>
                  </td>
                  <td className='indicador-medida'>Anos</td>
                </tr>
                <tr>
                  <td className='align-vertically-center'>FI</td>
                  <td className='align-vertically-center'>Fator de Localização do Prédio Urbano</td>
                  <td className='indicador-medida'>
                    <span>{item['fator_localizacao ']}</span>
                  </td>
                  <td className='indicador-medida'>Limite Unitário</td>
                </tr>
                <tr>
                  <td className='align-vertically-center'>Al</td>
                  <td className='align-vertically-center'>Área de Terreno que Serve de Logradouro</td>
                  <td className='indicador-medida'>
                    <span>{valueAL}</span>
                  </td>
                  <td className='indicador-medida'>m²</td>
                </tr>
                <tr style={{ backgroundColor: '#f2f2f2' }}>
                  <td colSpan="4">
                    <td className="valor-patrimonial" colSpan="4">
                      <strong>Valor Patrimonial [Vp]:</strong>
                      <span><strong>{valorPatrimonial} MZN</strong></span>
                    </td>
                  </td>
                </tr>
                <tr style={{ backgroundColor: '#f2f2f2' }}>
                  <td colSpan="4">
                    <td className="valor-patrimonial" colSpan="4">
                      <strong>IPRA:</strong>
                      <span><strong>{ipraValue} MZN</strong></span>
                    </td>
                  </td>
                </tr>
              </tbody>
            ))}
          </table>
          <div className='div_divider'></div>
        </>
          :
          <ValEstipulado finalidade={finalidade} />
        }
        <div className="observacoes" style={{ fontSize: "16px", lineHeight: "1.2" }}>
          <br />
          <hr />
          <h2>Observações:</h2>
          <p>&nbsp;1. O valor patrimonial de imóveis é regulado pelo Decreto n. 61/2010 de 27 de Dezembro.</p>
          <p>&nbsp;2. A fórmula a ser aplicada no cálculo do VP é: <strong>Vp = (Ae x P x Fa + 0.05 x Al x P) FI</strong>.</p>
          <p>&nbsp;3. A fórmula a ser aplicada no cálculo do IPRA é: <strong>Vipra = Vp x Taxa</strong>.</p>
        </div>
        <div>
        </div>
      </div>
      <br />
      <br />
      <br />
      <Footer />
    </div>
  );
};

export default SimuladorValPatrimonialIpra;
