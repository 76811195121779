import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import IpraSimulador from "./pages/simulador";
import NotFoundPage from "./pages/404NotFound";
import Footer from "./components/layout/footer/footer";

function AppRoutes() {
    return (
        <>
            <Router>
                <Routes >
                    <Route path="/" element={<IpraSimulador />} exact />
                    <Route path="*" element={<NotFoundPage />} exact />
                </Routes>
            </Router>
            {/* <Footer /> */}
        </>
    )
}

export default AppRoutes;
