import React from 'react';
import { Link } from 'react-router-dom';
import Navbar from '../components/layout/header/navbar';

function NotFoundPage() {
    return (
        <>
            <Navbar />
            <div className="container mt-5">
                <div className="row justify-content-center">
                    <div className="col-md-6 text-center">
                        <h1 className="display-4">404</h1>
                        <p className="lead">Oops! A página não foi encontrada.</p>
                        <p>Desculpe, a página que está procurando parece ter sido removida ou está temporariamente indisponível.</p>
                        <Link to="/" className="btn btn-primary mt-3">Voltar a página principal</Link>
                    </div>
                </div>
            </div>
        </>
    );
}

export default NotFoundPage;
